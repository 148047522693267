import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import PageContainer from '../../common/PageContainer';
import { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import uuid from 'react-uuid';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const StyledWrapper = styled.div`
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
	background-color: ${({ theme }) => theme.colors.beige};
`;

const StyledInfoWrapper = styled.div`
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
`;

const StyledHeader = styled.h1`
	max-width: 1600px;
	color: ${({ theme }) => theme.colors.white};
	font-weight: ${({ theme }) => theme.fontWeight.light};
	font-size: 120px;
	@media (max-width: 765px) {
		font-size: 38px;
	}
	@media (min-width: 766px) and (max-width: 1365px) {
		font-size: 69px;
	}
`;
const StyledDescription = styled.div`
	margin-top: 48px;
	font-size: 20px;
	line-height: 29px;
	color: ${({ theme }) => theme.colors.darkBlue};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	@media (min-width: 1366px) {
		max-width: 627px;
	}
`;

const StyledBackgroundCircle = styled.div`
	display: block;
	width: 200vw;
	height: 200vw;
	top: -50%;
	left: -50%;
	z-index: -1;
	position: absolute;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.lightRed};
	@media (min-width: 1366px) {
		width: 70vw;
		height: 70vw;
		top: -75%;
		left: -10%;
	}
	@media (min-width: 766px) and (max-width: 1365px) {
		width: 150vw;
		height: 150vw;
		top: -40%;
		left: -25%;
		@media (orientation: landscape) {
			top: -140%;
			left: -25%;
		}
	}
`;

const StyledContentWrapper = styled.div`
	width: auto;
	display: flex;
`;

const StyledTextWrapper = styled.div`
	position: relative;
	min-width: 100vw;
	@media (max-width: 1365px) {
		text-align: center;
	}
	@media (min-width: 766px) and (max-width: 1365px) {
		@media (orientation: landscape) {
			padding: 150px;
		}
	}
`;

const StyledSlidesContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	height: 100%;
	align-items: start;
	@media (max-width: 1365px) {
		margin-left: 100px;
	}
	@media (min-width: 766px) {
		gap: 100px;
	}
`;

const StyledSlide = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	opacity: 50%;
	transition: opacity 0.2s ease-in-out;
	justify-content: center;
	&.active {
		opacity: 1;
	}
	@media (min-width: 766px) {
		flex-direction: row;
	}
`;

const StyledSlideNumber = styled.div`
	color: ${({ theme }) => theme.colors.beige};
	font-size: 70px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	margin-right: 22px;

	@media (min-width: 1366px) {
		margin-top: 35px;
	}
`;
const StyledSlideInfo = styled.div`
	color: ${({ theme }) => theme.colors.white};

	> div {
		margin-top: 24px;
		font-size: 20px;
		font-weight: ${({ theme }) => theme.fontWeight.book};
		line-height: 29px;
	}
	> h1 {
		min-width: 90vw;
		font-size: 120px;
		font-weight: ${({ theme }) => theme.fontWeight.light};
		@media (min-width: 300px) and (max-width: 765px) {
			font-size: 36px;
		}
		@media (min-width: 766px) {
			min-width: 500px;
		}
	}
`;

const StyledProgressBar = styled.div`
	display: block;
	width: 113px;
	height: 4px;
	background-color: rgba(255, 213, 176, 0.1);
	position: absolute;
	left: calc(50% - 56px);
	bottom: 46px;
	z-index: -1;
	border-radius: 60px;

	@media (max-width: 1365px) {
		bottom: 96px;
	}
`;

const StyledCurrentProgress = styled.div`
	position: absolute;
	border-radius: 60px;
	top: 0;
	left: 0;
	width: 0;
	height: 4px;
	background-color: ${({ theme }) => theme.colors.beige};
`;

const ProcessModule = ({ processInfo, processSliderItems }) => {
	const backgroundCircle = useRef();
	const textWrapper = useRef();
	const slideWrapper = useRef();
	const contentWrapper = useRef();
	const progressBar = useRef();
	const theme = useTheme();
	let windowWidth = 1920;
	let textWrapperWidth = 0;
	let slideWrapperWidth = 0;
	if (typeof window !== 'undefined') {
		windowWidth = window.innerWidth;
	}

	const infoHeader = processInfo?.processInfoHeader || '';
	const infoDescription = processInfo?.processInfoDescription || '';
	const sliderItems = processSliderItems || [];

	const processAnimation = gsap.timeline({ paused: true });
	let slides = [];
	const processSTSettings = {
		bgCircleVars: {
			transform: 'scale(6)',
			ease: 'none',
		},
		progressBarVars: {
			width: 113,
			ease: 'none',
		},
		params: {
			start: 'top top',
		},
	};
	let startBreakpoint = 0;
	let endBreakpoint = 1;

	const disableSlideActiveClass = (slide) => {
		slide.classList.remove('active');
	};

	const setScrollTrigger = () => {
		const slideWidth = document.querySelector('.slide').getBoundingClientRect().width;
		const ratio = slideWidth / windowWidth;
		startBreakpoint = 0.5 - ratio / 2;
		endBreakpoint = 0.5 + ratio / 2;
		textWrapperWidth = textWrapper.current.getBoundingClientRect().width;
		slideWrapperWidth = slideWrapper.current.getBoundingClientRect().width;
	};

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const trigger = document.getElementById('process');
		setScrollTrigger();
		slides = gsap.utils.toArray('.slide');

		processAnimation
			.to(backgroundCircle.current, { ...processSTSettings.bgCircleVars }, 0)
			.to(progressBar.current, { ...processSTSettings.progressBarVars }, 0)
			.to(
				contentWrapper.current,
				{
					x: -(textWrapperWidth / 2.5 + slideWrapperWidth),
					ease: 'none',
				},
				0,
			)
			.to({}, { duration: 0.02, ease: 'none' });

		ScrollTrigger.create({
			id: 'processST',
			trigger,
			pin: true,
			pinSpacing: true,
			start: processSTSettings.params.start,
			end: `+=${slideWrapperWidth + windowWidth / 2}`,
			invalidateOnRefresh: true,
			animation: processAnimation,
			scrub: 0.7,
			onUpdate: () => {
				slides.forEach((slide) => {
					const pos = ScrollTrigger.positionInViewport(slide, 'center', true);
					//horizontal position of slide on screen in value between (0,1)
					if (startBreakpoint < pos && pos < endBreakpoint) {
						slide.classList.add('active');
					} else {
						disableSlideActiveClass(slide);
					}
				});
			},
			onLeave: () => {
				const pinSpacer = document.querySelector('.pin-spacer-processST');
				slides.forEach((slide) => {
					disableSlideActiveClass(slide);
				});
				pinSpacer.style.backgroundColor = theme.colors.lightRed;
			},
			onLeaveBack: () => {
				const pinSpacer = document.querySelector('.pin-spacer-processST');
				slides.forEach((slide) => {
					disableSlideActiveClass(slide);
				});
				pinSpacer.style.backgroundColor = theme.colors.beige;
			},
		});

		return () => {
			const processST = ScrollTrigger.getById('processST');
			processST?.kill();
		};
	}, []);

	const handleResize = () => {
		setScrollTrigger();
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledWrapper id="process">
			<StyledInfoWrapper>
				<StyledBackgroundCircle id="backgroundCircle" ref={backgroundCircle} />
				<StyledContentWrapper ref={contentWrapper}>
					<StyledTextWrapper ref={textWrapper}>
						<PageContainer className="content-standard">
							<StyledHeader>{infoHeader}</StyledHeader>
							<StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(infoDescription)} />
						</PageContainer>
					</StyledTextWrapper>
					{windowWidth >= 1366 ? (
						<StyledSlidesContainer ref={slideWrapper}>
							{sliderItems.map((item) => {
								let newHeader = '';
								const originalHeader = item?.header.trim() || '';
								const headerSplitted = originalHeader.split(' ');
								headerSplitted.forEach((split, index) => {
									if (split.length === 1) {
										split += '&nbsp;';
										newHeader += split;
									} else {
										if (index + 1 === headerSplitted.length) {
											newHeader += split;
										} else {
											newHeader += split + ' ';
										}
									}
								});
								const number = item?.number || '';
								const description = item?.description || '';

								return (
									<StyledSlide className="slide" key={uuid()}>
										<StyledSlideNumber>{number}</StyledSlideNumber>
										<StyledSlideInfo>
											<h1 dangerouslySetInnerHTML={getHtmlFromRedactorField(newHeader)} />
											<div dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
										</StyledSlideInfo>
									</StyledSlide>
								);
							})}
						</StyledSlidesContainer>
					) : (
						<StyledSlidesContainer ref={slideWrapper}>
							{sliderItems.map((item) => {
								let newHeader = '';
								const originalHeader = item?.header.trim() || '';
								const headerSplitted = originalHeader.split(' ');
								headerSplitted.forEach((split, index) => {
									if (split.length === 1) {
										split += '&nbsp;';
										newHeader += split;
									} else {
										if (index + 1 === headerSplitted.length) {
											newHeader += split;
										} else {
											newHeader += split + ' ';
										}
									}
								});
								const number = item?.number || '';
								const description = item?.description || '';
								return (
									<StyledSlide className="slide" key={uuid()}>
										<PageContainer className="content-standard">
											<StyledSlideNumber>{number}</StyledSlideNumber>
											<StyledSlideInfo>
												<h1 dangerouslySetInnerHTML={getHtmlFromRedactorField(newHeader)} />
												<div dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
											</StyledSlideInfo>
										</PageContainer>
									</StyledSlide>
								);
							})}
						</StyledSlidesContainer>
					)}
				</StyledContentWrapper>

				<StyledProgressBar id="progress-bar">
					<StyledCurrentProgress ref={progressBar} />
				</StyledProgressBar>
			</StyledInfoWrapper>
		</StyledWrapper>
	);
};

export default ProcessModule;
