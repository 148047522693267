import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import PageContainer from '../../common/PageContainer';
import BgVid from '../../images/1062855274-hd.mp4';
import EllipseDark from '../../common/EllipseDark';
import showMe from '../../animations/showMe';

const StyledWrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 15, 44, 1);
  background-image: linear-gradient(to bottom right, rgba(24, 58, 119, 1), rgba(0, 15, 44, 1)); /* For browsers that do not support gradients */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const StyledContent = styled.div`
  max-width: 660px;
  text-align: center;
`;

const StyledBigHeader = styled.h1`
  color: ${({ theme }) => theme.colors.beige};
  font-size: 54px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 60px;
  opacity: 0;
  position: relative;
  top: 100px;

  @media (min-width: 300px) and (max-width: 765px) {
    font-size: 38px;
    line-height: 46px;
  }
`;
const StyledSmallHeader = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 29px;
  margin-top: 24px;
  opacity: 0;
  position: relative;
  top: 100px;

  @media (min-width: 300px) and (max-width: 765px) {
    font-size: 20px;
  }
`;

const StyledScrollWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: ${({ isMobile }) => (!isMobile ? 'calc(100% - 104.03px) ' : 'calc(100% - 160.03px)')};
`;

const StyledScrollText = styled.p`
  color: ${({ theme }) => theme.colors.beige};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 5.46px;
  line-height: 22px;
  margin-bottom: 13px;
`;
const StyledImg = styled.img`
  cursor: pointer;
  width: 40px;
`;
const StyledVideoBg = styled.video`
  height: 100vh;
  left: 0;
  mix-blend-mode: overlay;
  object-fit: cover;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
`;

const HomeModule = ({ homeData }) => {
	const [isMobileDevice, setIsMobileDevice] = useState(false);
	const background = homeData.homeBackground.fluid.src;
	const bigHeader = homeData?.homeBigHeader || '';
	const smallHeader = homeData?.homeSmallHeader || '';
	const scrollIcon = homeData?.homeScrollIcon?.url || '';
	const scrollIconAlt = homeData?.homeScrollIcon?.alt || '';
	const scrollIconText = homeData?.homeScrollIcon?.title || '';

	const ellipseParams = {
		width: '100%',
		height: '100%',
		viewBox: '100 100 700 700',
		position: 'absolute',
		opacity: 0.6,
	};

	useEffect(() => {
		setIsMobileDevice(isMobile);
	}, []);

	useEffect(() => {
		const showContent = document.querySelectorAll('.show-me');

		showMe(showContent[0], { autoAlpha: 1, delay: 0.3, top: 0, duration: 0.85, ease: 'power2.out' });
		showMe(showContent[1], { autoAlpha: 1, delay: 0.5, top: 0, duration: 0.85, ease: 'power2.out' });
	});

	const handleVideoAutoplay = () => {
		const videoElement = document.getElementById('banner-video');
		const videoElementFooter = document.getElementById('footer-video');
		if (!videoElement.playing) {
			videoElement.play();
		}
		if (!videoElementFooter.playing) {
			videoElementFooter.play();
		}
	}

	useEffect(() => {
		const bodyElement = document.getElementsByTagName('body')[0];
		bodyElement.addEventListener('touchstart', handleVideoAutoplay);
		return () => {
			bodyElement.removeEventListener('touchstart', handleVideoAutoplay);
		}
	},[])

	useEffect(() => {
		const videoElement = document.getElementById('banner-video');
		if(videoElement) {
			videoElement.setAttribute('autoplay', "true");
			videoElement.play();
			videoElement.addEventListener('suspend', () => {
				if(isMobileDevice) {
					videoElement.removeAttribute('autoplay');
				}
			});
		}
		return () => {
			videoElement.removeEventListener('suspend', () => {
				if(isMobileDevice) {
					videoElement.removeAttribute('autoplay');
				}
			});
		}
	})

	return (
		<StyledWrapper id="home">
			<EllipseDark ellipseParams={ellipseParams} />
			<StyledVideoBg id='banner-video' autoplay playsInline muted loop className="bgvid">
				<source src={BgVid} type="video/mp4" />
			</StyledVideoBg>
			<PageContainer className="content-standard">
				<StyledContent>
					<StyledBigHeader className="show-me" dangerouslySetInnerHTML={getHtmlFromRedactorField(bigHeader)} />
					<StyledSmallHeader className="show-me" dangerouslySetInnerHTML={getHtmlFromRedactorField(smallHeader)}/>
				</StyledContent>
			</PageContainer>
			<StyledScrollWrapper id="scroll-wrapper" isMobile={isMobile}>
				<StyledScrollText>{scrollIconText}</StyledScrollText>
				<StyledImg
					src={scrollIcon}
					alt={scrollIconAlt}
					onClick={(e) => {
						window.scrollTo({
							top: document.getElementById('projects').offsetTop,
							behavior: 'smooth',
						});
					}}
				/>
			</StyledScrollWrapper>
		</StyledWrapper>
	);
};

export default HomeModule;
