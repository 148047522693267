import React, { useEffect } from 'react';
import styled from 'styled-components';
import PageContainer from '../../common/PageContainer';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import uuid from 'react-uuid';

const StyledWrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-color: ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	align-items: end;
	div.content-standard {
		width: 100%;
	}
	@media (max-width: 765px) {
		div.content-standard {
			margin: auto;
			transform: translateY(10%);
		}
	}
	@media (min-width: 766px) {
		div.content-standard {
			margin: auto;
			transform: translateY(30%);
		}
	}
`;

const StyledLogo = styled.img`
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	@media (min-width: 766px) {
		top: calc(50% + 50px);
	}
`;

const StyledGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	grid-auto-rows: 50px;
	align-items: center;
	justify-items: center;
	&.active {
		opacity: 1;
	}
	@media (max-width: 765px) {
		grid-template-columns: 1fr 1fr;
		row-gap: 50px;
		margin: auto 23px;
	}
	@media (min-width: 766px) {
		row-gap: 84px;
	}
`;

const StyledClientLogo = styled.img`
	max-width: 100%;
`;

const ClientsModule = ({ clientsInfo, clientsItems }) => {
	const logoWhite = clientsInfo?.logo?.url || '';
	const logoWhiteAlt = clientsInfo?.logo?.alt || '';
	let windowWidth = 1920;
	if (typeof window !== 'undefined') {
		windowWidth = window.innerWidth;
	}

	let clientSTVars = {
		width: '200px',
		top: '150px',
		left: 'calc(50% - 100px)',
		ease: 'none',
	};

	const setSTSettings = () => {
		let width = 300;
		if (windowWidth >= 1921) {
			width = 400;
			clientSTVars = {
				width: `${width.toString()}px`,
				top: `${width.toString() / 2}px`,
				left: `calc(50% - ${width.toString() / 2}px)`,
				ease: 'none',
			};
		} else if (windowWidth >= 1366 && windowWidth < 1921) {
			clientSTVars = {
				width: `${width.toString()}px`,
				top: `150px`,
				left: `calc(50% - ${width.toString() / 2}px)`,
				ease: 'none',
			};
		} else if (windowWidth >= 768 && windowWidth < 1366) {
			width = 250;
			clientSTVars = {
				width: `${width.toString()}px`,
				top: `${width.toString() / 2}px`,
				left: `calc(50% - ${width.toString() / 2}px)`,
				ease: 'none',
			};
		} else {
			width = 200;
			clientSTVars = {
				width: `${width.toString()}px`,
				top: `${width.toString() / 2}px`,
				left: `calc(50% - ${width.toString() / 2}px)`,
				ease: 'none',
			};
		}
	};

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
		const trigger = document.getElementById('clients');
		const scaleAndMove = gsap.timeline({ paused: true });
		const logo = document.getElementById('logo');
		setSTSettings();

		scaleAndMove.to(logo, { ...clientSTVars });

		ScrollTrigger.create({
			id: 'logo',
			trigger,
			start: 'top top',
			end: `+=${trigger.offsetWidth}`,
			pin: true,
			animation: scaleAndMove,
			scrub: 0.7,
			invalidateOnRefresh: true,
			pinSpacing: true,
			onUpdate: (self) => {
				if (self.progress > 0.9) document.getElementById('logos').classList.add('active');
				else document.getElementById('logos').classList.remove('active');
			},
			onEnterBack: (self) => {
				document.getElementById('logos').classList.remove('active');
			},
		});

		return () => {
			const logoST = ScrollTrigger.getById('logo');
			logoST?.kill();
		};
	}, []);

	const handleResize = () => {
		setSTSettings();
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledWrapper id="clients">
			<StyledLogo id="logo" src={logoWhite} alt={logoWhiteAlt} />
			<PageContainer className="content-standard">
				<StyledGrid id="logos">
					{clientsItems &&
						clientsItems.map((item) => {
							const logo = item.clientsClientLogo.url || '';
							const alt = item.clientsClientLogo.alt || '';
							return <StyledClientLogo key={uuid()} src={logo} alt={alt} />;
						})}
				</StyledGrid>
			</PageContainer>
		</StyledWrapper>
	);
};

export default ClientsModule;
