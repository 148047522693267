import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import uuid from 'react-uuid';
import PageContainer from '../../common/PageContainer';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import getProjectSlug from '../../utils/getProjectSlug';

const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.creamy};
	padding: 100px 0;
	width: 100%;
	@media (min-width: 300px) and (max-width: 765px) {
		padding: 73px 0;
	}
`;
const StyledGrid = styled.div`
	column-gap: 15px;
	display: grid;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	grid-template-columns: 1fr 1fr;
	width: 100%;
	@media (min-width: 300px) and (max-width: 765px) {
		grid-template-columns: 1fr;
	}
`;
const StyledGridItem = styled.div`
	align-items: flex-start;
	border-bottom: solid 1px darkgray;
	color: ${({ theme }) => theme.colors.blue};
	display: flex;
	font-size: 40px;
	padding: 46px 0;
	transition: color 0.2s ease-in-out;
	@media (min-width: 300px) and (max-width: 765px) {
		font-size: 34px;
		padding: 20px 0;
	}
`;

const StyledGridInfo = styled.div`
	align-items: end;
	border-bottom: solid 1px darkgray;
	color: ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	padding-bottom: 66px;
	@media (min-width: 300px) and (max-width: 765px) {
		padding-bottom: 20px;
		&:nth-of-type(1) {
			border-bottom: none;
		}
		&:nth-of-type(2) {
			padding-bottom: 46px;
		}
	}
`;

const StyledInfoHeader = styled.div`
	font-size: 54px;
	max-width: 456px;
	@media (min-width: 300px) and (max-width: 765px) {
		font-size: 38px;
		text-align: center;
	}
`;
const StyledInfoDesc = styled.div`
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};

	@media (min-width: 300px) and (max-width: 765px) {
		text-align: center;
	}
`;
const StyledTitle = styled.a`
	color: ${({ theme }) => theme.colors.blue};
	max-width: 465px;
	text-decoration: none;
	&:active,
	:visited {
		color: ${({ theme }) => theme.colors.blue};
	}
	@media (min-width: 766px) {
		:hover {
			cursor: pointer;
			color: ${({ theme }) => theme.colors.lightRed};
		}
	}
`;

const StyledPreview = styled.div`
	//width: 453px;
	background-position: top;
	background-repeat: no-repeat;
	background-size: contain;
	display: none;
	height: 375px;
	opacity: 1;
	position: absolute;
	transition: opacity 0.3s;
  	z-index: 2;
	@media(min-width: 1024px) {
		display: block; 
	  	width: 450px;
	}
	@media(min-width: 1280px) {
		width: 550px; 
	}
	@media(min-width: 1600px) {
		width: 600px;
	}
	@media(min-width: 1800px) {
		width: 660px;
	}
  
`;

const ProjectsModule = ({ lang, projectInfo, projectItems }) => {
	const previewContainer = useRef(null);
	let timeout = null;
	const infoHeader = projectInfo?.[0]?.projectsInfoHeader || '';
	const infoDescription = projectInfo?.[0]?.projectsInfoDescription || '';
	const [screenWidth, setScreenWidth] = useState(0);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			setScreenWidth(width);
		}
	}, []);

	const setStylesOnElement = (element, styles = '') => {
		Object.assign(element.style, styles);
	};

	const cursorOn = (item) => {
		clearTimeout(timeout);
		let left = 0;
		if (!isMobile) {
			const top = item.offsetTop - 150;
			const gridHalfWidth = document.getElementById('grid').offsetWidth / 2;
			const gridOffsetLeft = document.getElementById('grid').offsetLeft;
			// establish is it left or right column
			if (item.offsetLeft > gridHalfWidth) {
				// right
				if(screenWidth < 1280) {
					left = gridOffsetLeft + 10;
				} else if( screenWidth < 1800) {
					left = gridOffsetLeft + 45;
				} else {
					left = gridOffsetLeft + 100;
				}
			} else {
				// left
				if(screenWidth < 1280) {
					left = gridOffsetLeft + gridHalfWidth + 25;
				} else if( screenWidth < 1800) {
					left = gridOffsetLeft + gridHalfWidth + 45;
				} else {
					left = gridOffsetLeft + gridHalfWidth + 100;
				}
			}
			const styles = {
				visibility: 'visible',
				opacity: 1,
				backgroundImage: `url(${  item.dataset.preview  })`,
				left: `${left.toString()  }px`,
				top: `${top.toString()  }px`,
			};
			setStylesOnElement(previewContainer.current, styles);
		}
	};

	const cursorOff = () => {
		if (!isMobile) {
			timeout = setTimeout(() => {
				previewContainer.current.style.visibility = 'hidden';
			}, 250);
			previewContainer.current.style.opacity = 0;
		}
	};

	return (
		<StyledWrapper id="projects">
			<PageContainer className="content-standard">
				<StyledGrid id="grid">
					<StyledGridInfo>
						<StyledInfoHeader>{infoHeader}</StyledInfoHeader>
					</StyledGridInfo>
					<StyledGridInfo>
						<StyledInfoDesc dangerouslySetInnerHTML={getHtmlFromRedactorField(infoDescription)} />
					</StyledGridInfo>
					{projectItems?.map((item) => {
						const {src} = item.preview.fluid;
						const title = item.title || '';
						const slug = item.slug || '';
						return (
							<StyledGridItem key={uuid()}>
								<StyledTitle
									data-preview={src}
									onMouseOver={(e) => cursorOn(e.target)}
									onMouseLeave={(e) => cursorOff()}
									href={`/${getProjectSlug(slug, lang || '')}`}
								>
									{title}
								</StyledTitle>
							</StyledGridItem>
						);
					}) || ''}
				</StyledGrid>

				<StyledPreview ref={previewContainer} />
			</PageContainer>
		</StyledWrapper>
	);
};

export default ProjectsModule;
