import * as React from 'react';
import { graphql } from 'gatsby';
import Menu from '../components/Menu/Menu';
import ProjectsModule from '../components/ProjectsModule/ProjectsModule';
import ProcessModule from '../components/ProcessModule/ProcessModule';
import HomeModule from '../components/HomeModule/HomeModule';
import ClientsModule from '../components/ClientsModule/ClientsModule';
import ContactModule from '../components/ContactModule/ContactModule';
import Seo from '../components/Seo/Seo';

const HomePageTemplate = ({
	data: {
		homeData,
		projectData,
		processData,
		clientData,
		contactData,
		datoCmsSite,
	},
	pageContext,
}) => {
	const menuLogo = pageContext?.globals?.[0]?.menuLogo || null;
	const menuAltLogo = pageContext?.globals?.[0]?.menuAltLogo || null;
	const menuItems = pageContext?.globals?.[0]?.menuItems || null;
	const projectInfo = projectData?.nodes || null;
	const projectItems = projectData?.nodes?.[0]?.link || null;
	const processInfo = processData?.nodes?.[0] || null;
	const processSliderItems = processData?.nodes?.[0]?.processSliderSliderItem || null;
	const home = homeData?.nodes?.[0] || null;
	const clientsInfo = clientData?.nodes?.[0] || null;
	const clientsItems = clientData?.nodes?.[0]?.clientsClient || null;
	const contactInfo = contactData?.nodes?.[0] || null;
	const socialItems = pageContext?.globals?.[0]?.socials || null;
	const seoGlobal = datoCmsSite?.globalSeo || null;
	const lang = pageContext?.locale || '';
	return (
		<>
			 <Seo seoGlobal={seoGlobal} />
			<Menu lang={lang} menuLogo={menuLogo} menuAltLogo={menuAltLogo} menuItems={menuItems} socialItems={socialItems} />
			<HomeModule homeData={home} />
			<ProjectsModule lang={lang} projectInfo={projectInfo} projectItems={projectItems} />
			<ProcessModule processInfo={processInfo} processSliderItems={processSliderItems} />
			<ClientsModule clientsInfo={clientsInfo} clientsItems={clientsItems} />
			<ContactModule lang={lang} contactInfo={contactInfo} socialItems={socialItems} />
		</>
	);
};

export default HomePageTemplate;
export const query = graphql`
	query ($locale: String) {
		homeData: allDatoCmsHome(filter: { locale: { eq: $locale } }) {
			nodes {
				homeBigHeader
				homeSmallHeader
				homeBackground {
					fluid {
						src
					}
				}
				homeScrollIcon {
					alt
					title
					url
				}
			}
		}
		projectData: allDatoCmsProject(filter: { locale: { eq: $locale } }) {
			nodes {
				projectsInfoHeader
				projectsInfoDescription
				link {
					title
					slug
					preview {
						alt
						title
						fluid {
							src
						}
					}
				}
			}
		}
		processData: allDatoCmsProcess(filter: { locale: { eq: $locale } }, sort: { fields: processSliderSliderItem___number }) {
			nodes {
				processInfoDescription
				processInfoHeader
				processSliderSliderItem {
					header
					description
					number
				}
			}
		}
		clientData: allDatoCmsClient(filter: { locale: { eq: $locale } }) {
			nodes {
				logo {
					url
					alt
				}
				clientsClient {
					clientsClientLogo {
						url
						alt
					}
				}
			}
		}
		contactData: allDatoCmsContact(filter: { locale: { eq: $locale } }) {
			nodes {
				contactHeader
				contactBackgroundText
				contactFirstYear
				contactBackground {
					url
				}
				contactPrivacyPolicyText
				contactPrivacyPolicyLink {
					slug
				}
			}
		}
		datoCmsSite {
			globalSeo {
				facebookPageUrl
				fallbackSeo {
					image {
						url
					}
					title
					twitterCard
					description
				}
				siteName
				titleSuffix
				twitterAccount
			}
		}
	}
`;
